<template>
    <custom-vue-table class="pl-3 pr-3" title="Request List" :fields="fields" :url="listUrl"
                      :per-page="10" ref="table"
                      :extra-params="{ status }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                    <div class="p-3">
                        <validated-vue-select name="Status" :options="statusOptions"
                                              label="Status"
                                              v-model="status"/>
                    </div>
                </drop-btn>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'RequestListingComponentPurchase',
    data () {
        return {
            loading       : false,
            status        : '',
            id            : '',
            listUrl       : urls.purchaseAdmin.request.list,
            statusOptions : [
                { value : 'PO Request', label : 'PO Request' },
                { value : 'PO Generated', label : 'PO Generated' },
                { value : 'Partial PO Generated', label : 'Partial PO Generated' },
                { value : 'Rejected', label : 'Rejected' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'Request ID'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name      : 'requested_by',
                    sortField : 'requested_by',
                    title     : 'Requested By'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/purchase/order/request-list/' + rowData.id + '/details/');
        }
    }
};
</script>
